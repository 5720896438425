import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import exists from 'utils/exists';
import {
  faMapMarkerAlt,
  faCalendarAlt,
  faCircleExclamation,
  faSignalStream,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'containers/atoms/LinkContainer';
import DateCalendar from 'components/atoms/DateCalendar';
import Label, { variants as labelVariants } from 'components/atoms/Label';
import { useSelector } from 'react-redux';
import getLiveEventBadgeType from 'utils/getLiveEventBadgeType';
import Translate from '../../../containers/atoms/TranslateContainer';
import Card from '../../atoms/Card/Card';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import LiveEventBadge from '../../molecules/LiveEventBadge/LiveEventBadge';
import s from './EventCard.css';
import Typography from '../../atoms/Typography';
import pushToDataLayer from '../../../utils/pushToDataLayer';
import { generateFromToToString, formatDate, toIsoDate, isInFuture } from '../../../utils/dateUtil';
import ButtonPrimaryAlt from '../../atoms/buttons/ButtonPrimaryAlt';
import getPageId from '../../../selectors/getPageId';
import { EVENT_VIEWING_MODES } from '../../pages/EventPage/viewingModes';
import useAuth from '../../../utils/hooks/useAuth';

const clientId = 'ahkfyGEHCzuaNzQpymeY30934';

const parseLabelVariants = variant => {
  switch (variant) {
    case 'fullyBooked':
      return labelVariants.FULLY_BOOKED;
    case 'canceled':
      return labelVariants.CANCELED;
    case 'newDate':
      return labelVariants.NEW_DATE;
    default:
      return labelVariants.DEFAULT;
  }
};

const generateEventUrl = (start, end, hideTimeInformation, title, location) => {
  return `https://www.addevent.com/dir/?client=${clientId}&start=${toIsoDate(start)}&end=${toIsoDate(
    end
  )}&all_day_event=${hideTimeInformation}&title=${title}&location=${location}&timezone=Europe/Stockholm`;
};

const EventCard = ({ pageData, pageTitle, viewingMode, vimeoHasBeenReset }) => {
  const { login } = useAuth();
  const pageId = useSelector(getPageId);
  const liveEventBadgeType = getLiveEventBadgeType({
    vimeoLiveEventUrl: pageData?.vimeoLiveEventUrl,
    vimeoVideoUrl: pageData?.vimeoVideoUrl,
    startDateISO: pageData?.startDate,
    endDateISO: pageData?.endDate,
  });

  const signUp = () => {
    pushToDataLayer('eventSignup', {
      eventTitleId: `${pageTitle} (${pageId})`,
    });
  };

  const handleAddToCalenderClick = () => {
    pushToDataLayer('eventAddToCalender', {
      eventTitleId: `${pageTitle} (${pageId})`,
    });
  };

  const showLiveImage =
    pageData?.implementationType === 'live' ||
    (!(exists(pageData?.location) && exists(pageData?.streetAddress)) &&
      pageData?.implementationType === 'liveAndPhysical');

  const showMapImage =
    pageData?.implementationType !== 'live' && exists(pageData?.location) && exists(pageData?.streetAddress);

  const showLiveEventBadge = ['past'].includes(liveEventBadgeType);
  const isEventInFuture = isInFuture(pageData?.startDate);

  const shouldShowAddressInfo = () => {
    return (
      viewingMode !== '' &&
      viewingMode !== EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_GUEST_CONTENT &&
      viewingMode !== EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_MEMBER_CONTENT &&
      viewingMode !== EVENT_VIEWING_MODES.GUEST_VIEWING_MEMBER_CONTENT
    );
  };

  return (
    <Card classes={s.eventCard}>
      <div className={classnames(s.eventCardMap, showLiveImage && s.cardHeaderDark)}>
        {showMapImage && (
          <ResponsiveImage
            className={classnames(s.mapImage)}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${
              pageData?.streetAddress && pageData.streetAddress.replace(' ', '+').concat('', ',')
            }${pageData?.location && pageData.location.replace(' ', '+').concat('', ',')}${
              pageData?.eventTown && pageData.eventTown.replace(' ', '+')
            }&zoom=13&size=360x150&maptype=roadmap&markers=color:red%7C${
              pageData?.streetAddress && pageData.streetAddress.replace(' ', '+').concat('', ',')
            }${pageData?.location && pageData.location.replace(' ', '+').concat('', ',')}${
              pageData?.eventTown && pageData.eventTown.replace(' ', '+')
            }&key=AIzaSyDOThLKZhZyxApxj26H-Eo_fapoy1fL0vU`}
            alt="karta"
            width={360}
            height={150}
          />
        )}
        {showLiveImage && (
          <FontAwesomeIcon
            icon={faSignalStream}
            className={s.eventCardLiveHeadImage}
            style={{ width: 144, height: 128 }}
          />
        )}
        {!showMapImage && !showLiveImage && (
          <ResponsiveImage
            className={classnames(s.mapImage, s.blurMe)}
            src="https://maps.googleapis.com/maps/api/staticmap?center=Attlasv%C3%A4gen+41,Plan+123,Nacka&zoom=13&size=360x150&maptype=roadmap&markers=color:red%7CAttlasv%C3%A4gen+41,Plan+123,Nacka&key=AIzaSyDOThLKZhZyxApxj26H-Eo_fapoy1fL0vU"
            alt="karta"
            width={360}
            height={150}
          />
        )}
        <DateCalendar date={pageData?.startDate} classes={s.eventCardCalendar} />
      </div>
      <div className={s.eventCardInfo}>
        {showLiveEventBadge && <LiveEventBadge type={liveEventBadgeType} className={s.liveEventBadge} />}
        {pageData?.eventStatus !== 'default' && (
          <div className={s.labelsWrapper}>
            <Label variant={parseLabelVariants(pageData.eventStatus)} classes={s.labelItem} />
          </div>
        )}
        {(pageData?.implementationType === 'live' || pageData?.implementationType === 'liveAndPhysical') && (
          <div className={classnames(s.eventCardInfoPlace, s.eventCardInfoItem)}>
            <FontAwesomeIcon
              icon={faSignalStream}
              className={s.eventCardLiveImage}
              style={{ width: 22, height: 20 }}
            />
            <div>
              <Typography variant="paragraphSmall" component="div">
                {pageData?.implementationType === 'live' && <Translate>EVENT_PAGE.EVENT_LIVE</Translate>}
                {pageData?.implementationType === 'liveAndPhysical' && (
                  <Translate>EVENT_PAGE.EVENT_LIVE_AND_PHYSICAL</Translate>
                )}
              </Typography>
            </div>
          </div>
        )}
        {pageData?.implementationType !== 'live' &&
          shouldShowAddressInfo() &&
          (exists(pageData?.streetAddress) || exists(pageData?.location)) && (
            <div className={classnames(s.eventCardInfoPlace, s.eventCardInfoItem)}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className={s.eventCardMarkerImage} />
              <div>
                {pageData?.streetAddress && pageData?.streetAddress !== '' && (
                  <Typography variant="paragraphSmall" component="div">
                    {pageData.streetAddress}
                    {pageData.eventTown && `, ${pageData.eventTown}`}
                  </Typography>
                )}
                {pageData?.location && pageData?.location !== '' && (
                  <Typography variant="paragraphSmall" component="div">
                    {pageData.location}
                  </Typography>
                )}
                {pageData?.streetAddress && (
                  <Typography
                    to={`http://maps.google.com/maps?q=${pageData.streetAddress.replace(' ', '+')}, ${
                      pageData.eventTown && pageData.eventTown.replace(' ', '+')
                    }`}
                    variant="linkSmall"
                    component={Link}
                    target="_blank"
                    onClick={() => {
                      pushToDataLayer('showEventMap', {
                        eventTitleId: `${pageTitle} (${pageId})`,
                      });
                    }}
                  >
                    <Translate>EVENT_PAGE.OPEN_MAP</Translate>
                  </Typography>
                )}
              </div>
            </div>
          )}
        <div className={classnames(s.eventCardInfoPlace, s.eventCardInfoItem)}>
          <FontAwesomeIcon icon={faCalendarAlt} className={s.eventCardCalendarImage} />
          <div>
            {pageData?.startDate && (
              <Typography variant="paragraphSmall" component="div">
                {generateFromToToString(pageData.hideTimeInformation, pageData.startDate, pageData.endDate, {
                  showYear: true,
                })}
              </Typography>
            )}
            {isEventInFuture && exists(pageData?.startDate) && exists(pageData?.endDate) && (
              <Typography
                to={generateEventUrl(
                  pageData?.startDate,
                  pageData?.endDate,
                  pageData.hideTimeInformation,
                  pageTitle,
                  `${pageData.streetAddress}, ${pageData.eventTown} ${pageData.location}`
                )}
                variant="linkSmall"
                component={Link}
                target="_blank"
                onClick={handleAddToCalenderClick}
              >
                <Translate>EVENT_PAGE.ADD_TO_CALENDAR</Translate>
              </Typography>
            )}
          </div>
        </div>
        {isEventInFuture && exists(pageData?.externalForm) && (
          <ButtonPrimaryAlt
            block
            gutterBottom
            component="a"
            href={pageData.externalForm}
            onClick={signUp}
            target="_blank"
          >
            <Translate>EVENT_PAGE.SIGN_UP</Translate>
          </ButtonPrimaryAlt>
        )}
        {isEventInFuture && exists(pageData?.deadlineDate) && (
          <Typography variant="paragraphSmall" light component="div" classes={s.eventCardInfoButtomText}>
            {`Anmäl dig senast ${formatDate(pageData.deadlineDate)}`}
          </Typography>
        )}

        {!pageData?.vimeoLiveEventUrl &&
          !pageData?.vimeoVideoUrl &&
          !vimeoHasBeenReset &&
          viewingMode === EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_GUEST_CONTENT && (
            <div className={classnames(s.eventCardInfoPlace, s.eventCardInfoItem)}>
              <FontAwesomeIcon icon={faCircleExclamation} className={s.eventCardCalendarImage} />
              <div>
                {pageData?.startDate && (
                  <Typography variant="paragraphSmall" component="span">
                    Du måste vara inloggad för att kunna anmäla dig till detta event.{' '}
                  </Typography>
                )}
                <span onClick={login} className={s.link}>
                  Klicka här
                </span>
                <span> för att logga in eller skapa konto</span>
              </div>
            </div>
          )}

        {!pageData?.vimeoLiveEventUrl &&
          !pageData?.vimeoVideoUrl &&
          !vimeoHasBeenReset &&
          (viewingMode === EVENT_VIEWING_MODES.GUEST_VIEWING_MEMBER_CONTENT ||
            viewingMode === EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_MEMBER_CONTENT) && (
            <div className={classnames(s.eventCardInfoPlace, s.eventCardInfoItem)}>
              <FontAwesomeIcon icon={faCircleExclamation} className={s.eventCardCalendarImage} />
              <div>
                {pageData?.startDate && (
                  <Typography variant="paragraphSmall" component="div">
                    Detta event är bara öppet för Svenskt Näringslivs medlemsföretag.
                  </Typography>
                )}
                <Typography to="/medlem/snmo-konto" variant="linkSmall" component={Link}>
                  Läs mer om medlemsskapet
                </Typography>
              </div>
            </div>
          )}
      </div>
    </Card>
  );
};

export default withStyles(s)(EventCard);
