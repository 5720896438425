/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Typography from 'components/atoms/Typography';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
import s from './HeadImage.css';
import Translate from '../../../containers/atoms/TranslateContainer';

const HeadImage = ({
  classes,
  imageSrc,
  imageAlt,
  caption,
  photographer,
  twoColumnLayout,
  useFreeform,
  imageWidth,
  imageHeight,
}) => {
  const size = useFreeform ? 'FREEFORM' : 'LANDSCAPE';
  const sizes = getImages(size);
  const imageSizes = generateSizes({ lg: '834px', sm: '74vw', default: '100vw' });
  const classNames = classnames(classes, s.headImage, twoColumnLayout && s.twoColumnLayout);
  return (
    <div className={classNames}>
      <ResponsiveImage
        src={sizes.toSrc(imageSrc, 'M')}
        srcSet={sizes.toSrcSet(imageSrc)}
        sizes={imageSizes}
        alt={imageAlt}
        width={imageWidth}
        height={imageHeight}
      />
      <div className={s.captionContainer}>
        <Typography variant="imageCaption" component="span" classes={s.caption}>
          {caption}
        </Typography>
        {photographer && (
          <Typography variant="imageCaption" component="span" classes={s.photographer}>
            <Translate>IMAGE.PHOTO</Translate>: {photographer}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(HeadImage);
