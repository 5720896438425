import { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import useGlobalState from '../../../zustand/zustand';

const PageScroll = ({ data, scrollPosition }) => {
  const [rendered, setRendered] = useState(false);
  const shouldPollCook = useGlobalState(state => state.generalSettings.shouldPollCook);
  const prevDataRef = useRef(null);

  useEffect(() => {
    // Disable scroll to top if shouldPollCook is true since that causes the page to scroll to top
    // when a background cook fetch has been done.
    if (typeof window !== 'undefined' && !shouldPollCook) {
      if (rendered) {
        setTimeout(() => {
          const { resolution, context } = data;
          if (prevDataRef.current !== null && !isEqual({ resolution, context }, prevDataRef.current)) {
            window.scrollTo(0, scrollPosition);
          }
          prevDataRef.current = { resolution, context };
        }, 50);
      } else {
        const lastPos = window?.history?.state?.state?.scrollPosition;
        if (lastPos) {
          window.scrollTo(0, lastPos);
        }
      }
    }
    setRendered(true);
  }, [data, shouldPollCook]);
  return null;
};

export default PageScroll;
