import React, { Fragment } from 'react';
import Typography from 'components/atoms/Typography';
import QuoteArticleMain from 'components/atoms/Quote/QuoteArticleMain';
import QuoteArticleSidebar from 'components/atoms/Quote/QuoteArticleSidebar';
import VideoBlock from 'components/atoms/VideoBlock';
import ListItem from 'components/atoms/ListItem';
import RelatedArticles from 'components/molecules/RelatedArticles';
import List from 'components/molecules/List';
import InlineImage from 'components/molecules/InlineImage';
import HeadImage from 'components/molecules/HeadImage';
import Table from 'components/molecules/Table';
import RelatedArticle from 'components/molecules/RelatedArticle';
import pickFrom from 'utils/pickFrom';
// eslint-disable-next-line import/no-cycle
import FactsBox from 'components/molecules/FactsBox';
import SpeakerBox from 'components/organisms/SpeakerBox';
import AgendaBox from 'components/organisms/AgendaBox';
import AccordionItem from 'components/organisms/AccordionItem';
import ListArticles from 'components/organisms/ListArticles';
import ContactPersonBlock from 'components/organisms/ContactPersonBlock';
import SpecialShareBar from 'components/molecules/SpecialShareBar';
// eslint-disable-next-line import/no-cycle
import Timelines from 'components/molecules/Timelines';
import htmlParser from 'utils/htmlParser';
import exists from 'utils/exists';
import KeyFigures from 'components/organisms/KeyFigures';
import CollapsibleGroup from 'components/organisms/CollapsibleGroup';
import CoworkerProfile from '../components/organisms/CoworkerProfile/CoworkerProfile';
import LinkButton from '../components/molecules/LinkButton/LinkButton';
import LinkButtonGroup from '../components/organisms/LinkButtonGroup';
import SpecialRelatedArticles from '../components/molecules/SpecialRelatedArticles/SpecialRelatedArticles';
import EventItem from '../components/molecules/EventItem';
import NewsletterSubscribe from '../components/organisms/NewsletterSubscribe/NewsletterSubscribe';
import EmbedBlock from '../components/molecules/EmbedBlock/EmbedBlock';
import TOC from '../components/molecules/TOC';
import SelfSustainingCounty from '../components/organisms/SelfSustainingCounty/SelfSustainingCounty';
import LiveEvent from '../components/pages/EventPage/LiveEvent/LiveEvent';
import CrimeRegionMap from '../components/organisms/CrimeRegionMap/CrimeRegionMap';
import GridChild from '../components/pages/FlexibleSection/components/Layout/GridChild/GridChild';
import VFSForm from '../components/organisms/VFSForm/VFSForm';
import ImageCarousel from '../components/organisms/ImageCarousel/ImageCarousel';
import VFSMunicipalityForm from '../components/organisms/VFSMunicipalityForm/VFSMunicipalityForm';
import ImageQuoteCarousel from '../components/organisms/ImageQuoteCarousel/ImageQuoteCarousel';
import EventCard from '../components/organisms/EventCard/EventCard';
import Spacer from '../components/atoms/Spacer/Spacer';
import VFSTaxTicker from '../components/organisms/VFSTaxTicker/VFSTaxTicker';
import VFSResult1 from '../components/organisms/VFSResult1/VFSResult1';
import VFSResult2 from '../components/organisms/VFSResult2/VFSResult2';
import VFSResult3 from '../components/organisms/VFSResult3/VFSResult3';
import SelectedContent from '../components/organisms/SelectedContent/SelectedContent';
// eslint-disable-next-line import/no-cycle
import MyPage from '../components/organisms/MyPage/MyPage';
import EventsWebinars from '../components/organisms/EventsWebinars';
import { processLatestWebinars } from '../selectors/getLatestWebinars';
import { processUpcomingEvents } from '../selectors/getUpcomingEvents';
import slugify from './slugify';

const renderElement = (element, attrDarkMode = false) => {
  const darkMode = typeof attrDarkMode === 'number' ? false : attrDarkMode;

  // Create an id if there is none
  if (!element.id) {
    // eslint-disable-next-line no-param-reassign
    element.id = Math.random().toString(36).slice(2);
  }

  try {
    switch (element.type) {
      case 'headline.special': {
        const noTopMargin = pickFrom(element, 'fields.noTopMargin') === true;

        return (
          <Typography key={element.id} variant="h1" darkMode={darkMode} gutterBottom={!noTopMargin}>
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      }
      case 'table_of_content': {
        return <TOC key={element.id} items={element.items} fields={element.fields} />;
      }
      case 'headline': {
        const noTopMargin = pickFrom(element, 'fields.noTopMargin') === true;

        return (
          <Typography key={element.id} variant="h1Big" darkMode={darkMode} gutterBottom={!noTopMargin}>
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      }
      case 'headline2': {
        const noTopMargin = pickFrom(element, 'fields.noTopMargin') === true;

        return (
          <Typography
            key={element.id}
            variant="h2"
            darkMode={darkMode}
            gutterBottom
            gutterTop={!noTopMargin}
            id={slugify([element.fields.headline.rawText, element.id.slice(0, 6)].join('_'))}
          >
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      }
      case 'headline3': {
        const noTopMargin = pickFrom(element, 'fields.noTopMargin') === true;
        return (
          <Typography
            key={element.id}
            variant="h3"
            darkMode={darkMode}
            gutterBottomSmall
            gutterTop={!noTopMargin}
            id={slugify([element.fields.headline.rawText, element.id.slice(0, 6)].join('_'))}
          >
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      }
      case 'headline4': {
        const noTopMargin = pickFrom(element, 'fields.noTopMargin') === true;

        return (
          <Typography
            key={element.id}
            variant="h4"
            darkMode={darkMode}
            gutterBottomSmall
            gutterTop={!noTopMargin}
            id={slugify([element.fields.headline.rawText, element.id.slice(0, 6)].join('_'))}
          >
            {htmlParser(pickFrom(element, 'fields.headline.html'))}
          </Typography>
        );
      }
      case 'lead_text':
        return (
          <Typography key={pickFrom(element, 'id')} darkMode={darkMode} gutterBottom variant="lead">
            {htmlParser(pickFrom(element, 'fields.lead-text.html'), darkMode)}
          </Typography>
        );
      case 'paragraph':
        return (
          <Typography key={pickFrom(element, 'id')} darkMode={darkMode} gutterBottom>
            {htmlParser(pickFrom(element, 'fields.paragraph.html'), darkMode)}
          </Typography>
        );
      case 'flexible_paragraph': {
        const stylesObject = {};

        if (element?.fields?.fontSize?.rawText) {
          stylesObject.fontSize = element.fields.fontSize.rawText;
          stylesObject.lineHeight = 'unset';
        }

        if (element?.fields?.textAlignment?.rawText) {
          stylesObject.textAlign = element.fields.textAlignment.rawText;
        }

        return (
          <Typography
            key={pickFrom(element, 'id')}
            darkMode={darkMode}
            bold
            gutterBottom
            style={stylesObject}
          >
            {htmlParser(pickFrom(element, 'fields.paragraph.html'), darkMode)}
          </Typography>
        );
      }
      case 'event_slider': {
        const upcomingEventsTitle = element?.fields?.upcomingEventsTitle?.rawText || '';
        const latestWebinarsTitle = element?.fields?.latestWebinarsTitle?.rawText || '';
        const latestWebinars = processLatestWebinars(element?.latestWebinars);
        const upcomingEvents = processUpcomingEvents(element?.upcomingEvents);

        return (
          <EventsWebinars
            key={pickFrom(element, 'id')}
            selectedEvents={upcomingEvents}
            latestWebinars={latestWebinars}
            upcomingEventsTitle={upcomingEventsTitle}
            latestWebinarsTitle={latestWebinarsTitle}
            forFlexibleSection
          />
        );
      }
      case 'pull_quote': {
        const { quote, quotee, quotee2, quoteType } = element.fields;
        const Component = quoteType && quoteType.rawText === 'large' ? QuoteArticleMain : QuoteArticleSidebar;
        return (
          <Component
            key={pickFrom(element, 'id')}
            quote={quote?.rawText}
            name={quotee?.rawText}
            workTitle={quotee2?.rawText}
            darkMode={darkMode}
          />
        );
      }
      case 'relations': {
        return (
          <RelatedArticles
            key={pickFrom(element, 'id')}
            articles={element.children.map(el => ({
              id: el.id,
              relation: el.relation,
              ...el.relationData,
            }))}
            fields={element?.fields}
            darkMode={darkMode}
          />
        );
      }
      case 'special-relations': {
        return (
          <SpecialRelatedArticles
            key={pickFrom(element, 'id')}
            articles={element.children.map(el => ({
              id: el.id,
              relation: el.relation,
              ...el.relationData,
            }))}
            fields={element?.fields}
          />
        );
      }
      case 'image': {
        const alignment = pickFrom(element, 'fields.alignment.rawText');
        const pushRight =
          pickFrom(element, 'fields.pushToRightColumnOnDesktop.rawText') === 'true' ||
          pickFrom(element, 'fields.pushToRightColumnOnDesktop') === true;
        const useFreeform = pickFrom(element, 'fields.useFreeform') === true;
        const noMarginBottom = pickFrom(element, 'fields.noMarginBottom') === true;
        const fixedImageVersion =
          pickFrom(element, 'fields.fixedImageVersion.rawText') ?? 'useCueImageVersions';
        const rawImageUrl = pickFrom(element, 'relation.href')?.split('?forceDownloadFileName')?.[0];

        const width =
          fixedImageVersion === 'useOriginalImage'
            ? element.relation?.fields?.BASE_FREEFORM?.sourceWidth || undefined
            : element.relation?.fields?.BASE_LANDSCAPE?.sourceWidth ||
              element.relation?.fields?.BASE_LANDSCAPE?.width;
        const height =
          fixedImageVersion === 'useOriginalImage'
            ? element.relation?.fields?.BASE_FREEFORM?.sourceHeight || undefined
            : element.relation?.fields?.BASE_LANDSCAPE?.sourceHeight ||
              element.relation?.fields?.BASE_LANDSCAPE?.height;

        return (
          <InlineImage
            key={pickFrom(element, 'id')}
            rightAligned={alignment === 'right'}
            leftAligned={alignment === 'left'}
            pushRight={pushRight}
            imageSrc={pickFrom(
              element,
              'relation.image.href',
              'relation.profileImage[0].content.fields.BASE_LANDSCAPE.href_full'
            )}
            imageAlt={pickFrom(element, 'relation.image.alttext', 'relation.image.description')}
            caption={pickFrom(element, 'fields.caption.rawText', 'relation.image.description')}
            photographer={pickFrom(element, 'relation.fields.photographer')}
            darkMode={darkMode}
            useFreeform={useFreeform}
            noMarginBottom={noMarginBottom}
            fixedImageVersion={fixedImageVersion}
            rawImageUrl={rawImageUrl}
            width={width}
            height={height}
          />
        );
      }
      case 'headImage': {
        const useFreeform = pickFrom(element, 'fields.fixedImageVersion.rawText') === 'useFreeform';
        const imageWidth = useFreeform
          ? element?.relation?.fields?.BASE_FREEFORM?.sourceWidth
          : element?.relation?.fields?.BASE_LANDSCAPE?.sourceWidth ||
            element?.relation?.fields?.BASE_LANDSCAPE?.width;

        const imageHeight = useFreeform
          ? element?.relation?.fields?.BASE_FREEFORM?.sourceHeight
          : element?.relation?.fields?.BASE_LANDSCAPE?.sourceHeight ||
            element?.relation?.fields?.BASE_LANDSCAPE?.height;

        return (
          <HeadImage
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            key={pickFrom(element, 'id')}
            imageSrc={pickFrom(
              element,
              'relation.image.href',
              'relation.profileImage[0].content.fields.BASE_LANDSCAPE.href_full'
            )}
            imageAlt={
              pickFrom(element, 'relation.image.alttext', 'relation.image.description') ||
              pickFrom(
                element,
                'relation.profileImage[0].content.fields.alttext',
                'relation.profileImage[0].content.fields.description'
              )
            }
            caption={pickFrom(element, 'fields.caption.rawText', 'relation.image.description')}
            photographer={pickFrom(
              element,
              'relation.fields.photographer',
              'relation.profileImage[0].content.fields.photographer'
            )}
            useFreeform={useFreeform}
          />
        );
      }
      case 'relation':
      case 'headRelation': {
        const imageData = {
          originalSize: pickFrom(element, 'relationData.coverImage[0].content.originalPictureSize'),
          freeFormSourceWidth: pickFrom(
            element,
            'relationData.coverImage[0].content.fields.BASE_FREEFORM.sourceWidth'
          ),
          freeFormSourceHeight: pickFrom(
            element,
            'relationData.coverImage[0].content.fields.BASE_FREEFORM.sourceHeight'
          ),
        };
        const contentType = pickFrom(element, 'relation.contentType');
        return contentType === 'tv' ? (
          <VideoBlock
            key={pickFrom(element, 'relation.fields.assetId')}
            relatedVideo={{
              streamingProvider: pickFrom(element, 'relation.fields.streamingProvider'),
              caption: pickFrom(element, 'relation.fields.body'),
              id: pickFrom(element, 'relation.fields.assetId'),
            }}
            stretched={element.type === 'headRelation'}
            inHeadGroup={element.type === 'headRelation'}
          />
        ) : (
          <RelatedArticle
            key={pickFrom(element, 'id')}
            title={pickFrom(element, 'relationData.headline')}
            relationTitle={pickFrom(element, 'fields.relationTitle.rawText')}
            contentType={pickFrom(element, 'relation.contentType')}
            binary={pickFrom(element, 'relation.document[0].content.binary')}
            href={pickFrom(element, 'relationData.href')}
            imageSrc={pickFrom(element, 'relation.image.href')}
            imageAlt={pickFrom(element, 'relation.image.alttext', 'relation.image.description')}
            imageData={imageData}
          />
        );
      }
      case 'factbox': {
        const headline = element.children.find(el => el.type === 'headline');
        const image = element.children.find(el => el.type === 'simple_image');
        const imageHref = pickFrom(image, 'relation.image.href');
        const imageDescription = pickFrom(image, 'relation.image.alttext', 'relation.image.description');
        const storyline = element.children.filter(el => el.type !== 'headline' && el.type !== 'simple_image');
        return (
          <FactsBox
            key={pickFrom(element, 'id')}
            headline={pickFrom(headline, 'fields.headline.rawText')}
            imageSrc={imageHref}
            imageAlt={imageDescription}
            storyline={storyline}
          />
        );
      }
      case 'table': {
        const columnHeader = pickFrom(element, 'fields.columnheader');
        const rowHeader = pickFrom(element, 'fields.rowheader');
        let arrays;
        try {
          arrays = JSON.parse(pickFrom(element, 'fields.tableeditor.rawText'));
        } catch (error) {
          console.error(error);
          return undefined;
        }

        return (
          <Table
            key={pickFrom(element, 'id')}
            rowHeader={rowHeader}
            columnHeader={columnHeader}
            rows={arrays}
            darkMode={darkMode}
          />
        );
      }
      case 'ordered_list':
      case 'unordered_list': {
        return (
          <List key={pickFrom(element, 'id')} ordered={element.type === 'ordered_list'}>
            {element?.children?.reduce((acc, child) => {
              const html = pickFrom(child, 'fields.paragraph.html');
              if (exists(html)) {
                acc.push(
                  <ListItem key={child.id} darkMode={darkMode}>
                    {htmlParser(html, darkMode)}
                  </ListItem>
                );
              }
              return acc;
            }, [])}
          </List>
        );
      }
      case 'embed':
      case 'headEmbed': {
        return <EmbedBlock key={element.id} element={element} />;
      }
      case 'gallery':
      case 'headGallery': {
        const slideshow = element.children.map(item => {
          return {
            id: pickFrom(item, 'id'),
            caption: pickFrom(item, 'fields.caption.rawText'),
            href: pickFrom(item, 'relation.image.href'),
            altText: pickFrom(item, 'relation.image.alttext'),
            photographer: pickFrom(item, 'relation.fields.photographer'),
          };
        });

        return <ImageCarousel key={element.id} items={slideshow} />;
      }

      case 'speakerbox': {
        const headlineElement = element?.children?.find(child => child.type === 'speakerbox_headline');
        const speakersElements = element?.children?.filter(child => child.type !== 'speakerbox_headline');
        const speakers = speakersElements?.map(speaker => {
          const speakerId = pickFrom(speaker, 'id');
          const speakerImage = speaker.children?.find(child => child.type === 'simple_image');
          const speakerName = speaker.children?.find(child => child.type === 'speaker_name');
          const speakerTitle = speaker.children?.find(child => child.type === 'speaker_title');
          const speakerDescription = speaker.children?.find(child => child.type === 'paragraph');
          return {
            id: speakerId,
            imageSrc: speakerImage !== undefined ? pickFrom(speakerImage, 'relation.image.href') : '',
            imageAlt: speakerImage !== undefined ? pickFrom(speakerImage, 'relation.image.alttext') : '',
            name: pickFrom(speakerName, 'fields.name.rawText'),
            title: pickFrom(speakerTitle, 'fields.title.rawText'),
            description: pickFrom(speakerDescription, 'fields.paragraph.rawText'),
          };
        });

        let headline;
        if (headlineElement) {
          headline = pickFrom(headlineElement, 'fields.headline.rawText');
        }
        return <SpeakerBox key={element.id} headline={headline} speakers={speakers} />;
      }
      case 'agendabox': {
        const headlineElement = element?.children?.find(child => child.type === 'agendabox_headline');
        const agendasElements = element?.children?.filter(child => child.type !== 'agendabox_headline');
        const agendas = agendasElements?.map(agenda => {
          const agendaId = pickFrom(agenda, 'id');
          const agendaTime = agenda.children?.find(child => child.type === 'agendapoint_time');
          const agendaHeadline = agenda.children?.find(child => child.type === 'agendapoint_headline');
          const agendaDescription = agenda.children?.find(child => child.type === 'paragraph');

          return {
            id: agendaId,
            time: pickFrom(agendaTime, 'fields.time.rawText'),
            headline: pickFrom(agendaHeadline, 'fields.headline.rawText'),
            description: pickFrom(agendaDescription, 'fields.paragraph.rawText'),
          };
        });

        let headline;
        if (headlineElement) {
          headline = pickFrom(headlineElement, 'fields.headline.rawText');
        }
        return <AgendaBox key={element.id} headline={headline} agendas={agendas} />;
      }
      case 'profile': {
        return (
          <CoworkerProfile
            key={element?.id}
            darkMode={darkMode}
            image={element?.relation?.image}
            name={element?.relation?.name}
            fields={element?.fields}
            profileFields={element?.relation?.fields}
            highResolutionImages={element?.relation?.highResolutionImages}
            documents={element?.relation?.documents}
          />
        );
      }
      case 'link_button': {
        return (
          <LinkButton
            key={element.id}
            text={element.fields.text?.rawText}
            variant={element.fields.variant?.rawText}
            color={element.fields.color?.rawText}
            align={element.fields.alignment?.rawText}
            href={element.fields.uri?.rawText || element.relation.href}
            isExternal={!!element.fields.uri?.rawText}
            style={{ display: 'inline-block' }}
          />
        );
      }

      case 'link_button_group': {
        return <LinkButtonGroup key={element.id} buttons={element.items} />;
      }

      case 'event': {
        return <EventItem key={element.id} {...element} />;
      }

      case 'newsletter': {
        return <NewsletterSubscribe key={element?.id} newsletter={element?.relation} />;
      }

      case 'accordion_group': {
        return <AccordionItem key={element?.id} item={element?.children} />;
      }
      case 'contact_person_block': {
        return <ContactPersonBlock key={element?.id} persons={element?.children} darkMode={darkMode} />;
      }
      case 'timeline': {
        return <Timelines key={element?.id} timelines={element?.children} darkMode={darkMode} />;
      }
      case 'share_bar': {
        const showShareOnFacebook = element?.fields?.showShareOnFacebook;
        const showShareOnLinkedIn = element?.fields?.showShareOnLinkedIn;
        const showShareOnTwitter = element?.fields?.showShareOnTwitter;
        const showShareViaEmail = element?.fields?.showShareViaEmail;
        const color = element?.fields?.iconColor?.rawText;
        const title = element?.fields?.shareTitle?.rawText ?? '';
        const href = element?.fields?.shareUrl?.rawText;
        return (
          <SpecialShareBar
            key={element?.id}
            isShowShareOnFacebook={showShareOnFacebook}
            isShowShareOnLinkedIn={showShareOnLinkedIn}
            isShowShareOnTwitter={showShareOnTwitter}
            isShowShareViaEmail={showShareViaEmail}
            color={color}
            darkMode={darkMode}
            title={title}
            href={href}
          />
        );
      }
      case 'image_quote_slider': {
        return (
          <ImageQuoteCarousel
            key={element.id}
            items={element.children}
            bg={element?.fields?.background?.rawText}
          />
        );
      }

      case 'article_list': {
        return (
          <ListArticles
            key={element?.id}
            articleList={element.articleList ?? element?.element?.articleList}
            darkMode={darkMode}
          />
        );
      }
      case 'key_figures': {
        const keyFigures = element?.children?.map(keyFigure => {
          return {
            id: keyFigure.id,
            number: keyFigure.fields.number.rawText,
            text: keyFigure.fields.text.rawText,
          };
        });

        return <KeyFigures key={element.id} keyFigures={keyFigures} />;
      }
      case 'collapsible_group': {
        const variant = element.fields.collapseLinkVariant?.rawText ?? 'default';
        const isInitiallyExpanded = !!element.fields.initiallyExpanded;
        const readMoreText = element.fields.expandLinkText?.rawText || 'Läs mer';
        const readLessText = element.fields.collapseLinkText?.rawText || 'Fäll ihop';
        return (
          <CollapsibleGroup
            key={element.id}
            isInitiallyExpanded={isInitiallyExpanded}
            readMoreText={readMoreText}
            readLessText={readLessText}
            variant={variant}
            darkMode={darkMode}
          >
            {element.children.map(child => (
              <Fragment key={child.id}>{renderElement(child, darkMode)}</Fragment>
            ))}
          </CollapsibleGroup>
        );
      }

      case 'live_event': {
        return <LiveEvent key={element.id} isStorylineElement element={element} />;
      }

      case 'grid_item': {
        const gridChildOptions = Object.entries(element.fields).reduce(
          (acc, [currentKey, currentValue]) => ({
            ...acc,
            [currentKey]: currentValue.rawText ?? currentValue,
          }),
          {}
        );

        const isDarkMode = gridChildOptions.textColor === 'light';

        // Define background children types and find them
        const backgroundMediaChildrenTypes = ['background'];
        const foundBackgroundChild = element.children.find(child =>
          backgroundMediaChildrenTypes.includes(child.type)
        );

        // Build a new background object that fits the <BackgroundMediaLayer /> component props
        let backgroundMedia;
        if (foundBackgroundChild) {
          const bgMediaType = foundBackgroundChild.relation.contentType;
          backgroundMedia = {
            type: bgMediaType,
            href:
              bgMediaType === 'picture'
                ? foundBackgroundChild.relation.fields.BASE_LANDSCAPE.href_full
                : foundBackgroundChild.relation.binary.href,
            alt: bgMediaType === 'picture' ? foundBackgroundChild.relation.fields.alttext : undefined,
          };
        }

        // Set the backgroundMedia to the options object
        gridChildOptions.backgroundMedia = backgroundMedia;

        return (
          <GridChild key={element.id} options={gridChildOptions}>
            {element.children
              .filter(child => !backgroundMediaChildrenTypes.includes(child.type)) // Remove the backgroundMediaChildrenTypes
              .map(child => renderElement(child, isDarkMode))}
          </GridChild>
        );
      }
      case 'event_teaser': {
        const pageData = pickFrom(element, 'children[0].relation.fields');
        const pageTitle = pickFrom(element, 'children[0].relation.title');

        return <EventCard key={element.id} pageData={pageData} pageTitle={pageTitle} />;
      }
      case 'spacer': {
        return <Spacer key={element.id} options={element.fields} />;
      }
      case 'selected_content': {
        return (
          <SelectedContent
            key={element.id}
            selectedContent={element?.automaticSelectedContent || []}
            variant={element?.fields?.variant?.rawText}
          />
        );
      }
      case 'custom_component': {
        if (element.fields?.specialComponentName?.rawText === 'självförsörjning-kommun') {
          return <SelfSustainingCounty key={element.id} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'brott-i-regionerna') {
          return <CrimeRegionMap key={element.id} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'vfs-form') {
          return <VFSForm key={element.id} darkMode={darkMode} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'vfs-form2') {
          return <VFSMunicipalityForm darkMode={darkMode} key={element.id} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'vfs-taxticker') {
          return <VFSTaxTicker key={element.id} darkMode={darkMode} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'vfs-result1') {
          return <VFSResult1 key={element.id} darkMode={darkMode} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'vfs-result2') {
          return <VFSResult2 key={element.id} darkMode={darkMode} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'vfs-result3') {
          return <VFSResult3 key={element.id} darkMode={darkMode} />;
        }

        if (element.fields?.specialComponentName?.rawText === 'min-sida') {
          return <MyPage key={element.id} darkMode={darkMode} />;
        }
        return null;
      }
      default:
        return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export default renderElement;
