import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'containers/atoms/LinkContainer';
import s from './BlurryPlayer.css';
import { EVENT_VIEWING_MODES } from '../../viewingModes';
import useAuth from '../../../../../utils/hooks/useAuth';

const BlurryPlayer = ({ viewingMode }) => {
  const { login } = useAuth();

  return (
    <div className={s.container}>
      <div className={s.bg} />
      <div className={s.icon}>
        <FontAwesomeIcon icon={faPlayCircle} />
      </div>
      <div className={s.content}>
        {viewingMode === EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_GUEST_CONTENT && (
          <>
            <h2>Du måste vara inloggad för att se denna sändning</h2>
            <div onClick={login} className={s.link}>
              Klicka här för att logga in eller skapa konto
            </div>
          </>
        )}
        {(viewingMode === EVENT_VIEWING_MODES.UNAUTHENTICATED_VIEWING_MEMBER_CONTENT ||
          viewingMode === EVENT_VIEWING_MODES.GUEST_VIEWING_MEMBER_CONTENT) && (
          <>
            <h2>Denna sändning kan endast ses av Svenskt Näringslivs medlemsföretag</h2>
            <Link to="/medlem/snmo-konto" className={s.link}>
              Läs mer om medlemsskapet
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(s)(BlurryPlayer);
